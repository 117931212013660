<template>
  <div class="font-outfit">
    <!-- <PageBanner small page="about-us" class="mb-5 mb-lg-7" /> -->

    <main class="container py-5">
      <div class="row flex">
          <div class="col-md-4 px-3 py-3"><img class="illus" src="/img/about-illus-1.png"></div>
          <div class="col-md-8 px-3 py-3">
            <div class="mb-4 text-center text-md-left">
              <img src="/img/logo_asiaaseta.png" class="img-fluid"/>
            </div>
              <p>PT. Asia Aseta, as the parent company, brings imaginative and educational products to families through innovative brands like Bookabook.id, Bookabook ByMe, Karya Raya, and KreArt. Inspired by a mother’s vision, Asia Aseta personalizes reading experiences to spark children’s love for books and foster lifelong readers by making them the protagonists of their own stories.
                </p>
                <p>Since 2017, Bookabook has delighted over 12,000 children worldwide with personalized books for all life stages. We also organize events, workshops, and online online talks to support parents and the entire family in bonding and staying connected.</p>
          </div>
          
      </div>
      
      <!-- <div class="row flex">
          <div class="col-md-8 px-3 py-3">
              <p>Today, Bookabook has sold over 12,000 personalized books across Indonesia and the world. Our wide selection covers every stage of a child's development, including special occasions like birthdays and newborn celebrations. No matter the occasion, there is a perfect book for that special someone.</p>
              <p>We also organize events, workshops, and online talks to support parents and the entire family in bonding and staying connected.
              </p>
          </div>
          <div class="col-md-4 px-3 py-3"><img class="illus" src="/img/about-us-photo-2.png"></div>
      </div> -->

      <div class="row flex px-3">
        <h1 class="py-3" style="color:#58595B;">Our work is guided by three key principles:</h1>
      </div>

      <div class="row grid px-3" style="flex-wrap: wrap;">
        <div class="col-sm-12 col-md-4 px-md-4 px-sm-1" style="text-align:center">
          <div><img class="illus py-4" style="height:250px;padding:10px;" src="/img/about-step-1-illus.png"></div>
          <div><img class="illus py-4" style="padding:10px;height:100px;" src="/img/about-step-1.png"></div>
          <h1 style="color:#58595B;font-size:1.5em;text-align:center">• Family Connection •</h1>
          <p>
            We strive to spark children's creativity and imagination through our products, creating unforgettable experiences and adventures.
          </p>
        </div>
        <div class="col-sm-12 col-md-4 px-md-4 px-sm-1" style="text-align:center">
          <div><img class="illus py-4" style="height:250px;padding:10px;" src="/img/about-step-2-illus.png"></div>
          <div><img class="illus py-4" style="padding:10px;height:100px;" src="/img/about-step-2.png"></div>
          <h1 style="color:#58595B;font-size:1.5em;text-align:center">• Education •</h1>
          <p>Our approach is grounded in thorough research and the expertise of psychologists and children educators. We believe that the early years of childhood are the best time for learning.</p>
          
        </div>
        <div class="col-sm-12 col-md-4 px-md-4 px-sm-1" style="text-align:center">
          <div><img class="illus py-4" style="height:250px;padding:10px;" src="/img/about-step-3-illus.png"></div>
          <div><img class="illus py-4" style="padding:10px;height:100px;" src="/img/about-step-3.png"></div>
          <h1 style="color:#58595B;font-size:1.5em;text-align:center">• Family Connection •</h1>
          <p>We recognize the importance of strong relationships between children, parents, and siblings. That's why our focus extends beyond the individual child to encompass the whole family.</p>

        </div>
      </div>
      <div class="row px-3">
        <div>
        </div>
      </div>
      <div class="row flex my-3" style="text-align:left">
        <div class="pl-3">
            <h1 class="py-3" style="color:#58595B;font-size: 2.5em;">" We Want Every Child To Grow Up As A Passionate Reader "</h1>
        </div>
        <div><img class="illus" src="/img/home/illus_1.png"></div>
    </div>
      <div style="text-align: center;">
        <img class="img-fluid mx-md-4 mx-sm-1 py-4" style="max-width: 400px; width: 100%;" src="/img/label-part-of.png">
      </div>
      <div style="text-align: center;">
        <img class="illus px-md-4 px-sm-1 py-4" src="/img/bookabook-ecosystem.png">
      </div>

      
      <section>
        <div class="container text-center py-6 pt-lg-8">
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <h1 class="py-3" style="color:#58595B;font-size: 2.5em;">
                STILL NEED HELP?
              </h1>
              <p style="color:#58595B;font-size: 1em;margin-bottom: 20px;">
                Our Support Angels are standing by to answer your questions :)
              </p>
              <base-button
                block
                type="primary"
                @click="toSubmitMessagePage"
                style="background-color: #F7A41B;border:none;width: fit-content;margin:auto"
              >
                <span class="button-label">
                  Send Us a Message
                </span>
              </base-button>
            </div>
            <div class="col-md-6 col-sm-12">
              <img class="img-fluid mx-md-4 mx-sm-1 py-4" style="max-width: 400px; width: 100%; padding:20px" src="/img/mascot-help.png">
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
export default {
  components: {
    // PageBanner,
    // NeedHelp,
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/colors';

.section {
  margin-bottom: 48px;

  &__title {
    font-family: 'Outfit', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: $blue;
  }

  &__subtitle {
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: hsla(0, 0%, 0%, 0.7);
  }

  &__paragraph {
    font-size: 12px;
    color: hsla(0, 0%, 0%, 0.6);
  }

  &__divider {
    margin-top: 0px;
    margin-bottom: 16px;
  }

  &__figure {
    margin: 0 auto;
    width: 200px;
    max-width: 100%;
    margin-bottom: 28px;
  }

  &__figure-img {
    width: 100%
  }

  @media screen and (min-width: 992px) {
    margin-bottom: 72px;

    &__title {
      font-size: 32px;
    }

    &__subtitle {
      font-size: 24px;
    }

    &__paragraph {
      font-size: 16px;
    }

    &__divider {
      margin-bottom: 64px;
    }

    &__figure {
      width: 668px;
      margin-bottom: 36px;
    }
  }
}
</style>